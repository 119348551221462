<template>
  <div style="height: 100%">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="机构信息"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <el-form :model="orgDetail" :rules="rules" ref="orgDetail" label-width="100px" class="addhospital">
        <el-row :gutter="30">
          <el-col :span="8">
            <div class="form-box" style="padding-bottom: 50px">
              <div class="headImg">
                <div class="headimgBox" style="width: 150px;height: 150px;" v-if="ClientId == TotalClient">
                  <img v-if="ClientId == TotalClient"  src="../../../assets/img/orgLogo.jpg" alt="" />
                </div>
                <div class="headimgBox" v-else>
                  <img v-if="QRCode" :src="Services.Healthy + '/' + QRCode" alt="" />
                  <img v-else src="../../../assets/img/login_img.png" alt="" />
                </div>
                <!-- <el-button class="upload-btn" type="primary" icon="el-icon-edit" circle></el-button> -->
              </div>
              <el-form-item label="所属行业" prop="industryId">
                <el-select v-model="orgDetail.industryId" style="width: 100%" clearable placeholder="请选择所属行业" disabled>
                  <el-option v-for="(item, index) in industryList" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="机构级别" prop="levelId">
                <el-select v-model="orgDetail.levelId" style="width: 100%" clearable placeholder="请选择机构级别" disabled>
                  <el-option v-for="item in LevelData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="机构类型" prop="typeId">
                <el-select v-model="orgDetail.typeId" style="width: 100%" clearable placeholder="请选择机构级别" disabled>
                  <el-option v-for="item in TypeData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="机构状态">
                <el-radio-group size="medium" v-model="orgDetail.statu">
                  <el-radio-button :label="1">启用</el-radio-button>
                  <el-radio-button :label="-1">禁用</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="分销模式" prop="distributionMode">
                <el-radio-group style="display: flex; margin-top: 11px" v-model="orgDetail.distributionMode">
                  <el-radio :label="0">机构模式</el-radio>
                  <el-radio :label="1">个人模式</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="16">
            <el-scrollbar class="left-nav-wrap" style="height: 620px">
              <div class="form-box">
                <span>机构资料</span>
                <el-divider></el-divider>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="机构名称" prop="name" disabled>
                      <el-input v-model="orgDetail.name" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="机构简称" prop="alias">
                      <el-input v-model="orgDetail.alias" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="邮箱" prop="mail">
                      <el-input v-model="orgDetail.mail" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="负责人电话" prop="phone">
                      <el-input v-model="orgDetail.phone" autocomplete="off" disabled></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人">
                      <el-input v-model="orgDetail.contactName" autocomplete="off"> </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人电话">
                      <el-input v-model="orgDetail.contactPhone" autocomplete="off"> </el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="24">
                    <el-form-item label="机构地址" prop="regionId">
                      <el-cascader :placeholder="orgDetail.region ? orgDetail.region.fullName : '请选择所在区域'" v-model="orgDetail.regionId" :options="RegionData" size="medium" :props="{ emitPath: false, multiple: false, checkStrictly: true, lazy: true, lazyLoad: LasyLoadRegion, value: 'id', label: 'regionName', children: 'childrens' }" style="margin-right: 10px; width: 100%"> </el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="详细地址" prop="address">
                      <el-input placeholder="地图选点" v-model="orgDetail.address" disabled> </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="补充地址">
                      <el-input placeholder="补充地址" v-model="orgDetail.addressOther"> </el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="24">
                    <baidu-map class="bm-view" scroll-wheel-zoom :center="location" :zoom="zoom" ak="C3L8oD0NokcKDAyhtGZgTtwF1R4eqo7S" @ready="mapReady">
                      <bm-view class="map" style="width: 100%; height: 100%; flex: 1"></bm-view>
                      <!-- 自定义控件 --><!-- 自动填充 -->
                      <bm-control>
                        <bm-auto-complete :sugStyle="{ zIndex: 999999 }">
                          <el-input size="small" type="text" id="searchAddres" v-model="searchAddresKeywords" placeholder="请输入地点" @change="confirmAddress"> </el-input>
                        </bm-auto-complete>
                      </bm-control>
                      <!-- 手动放大缩小到省市街道 位置：右下方-->
                      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
                      <bm-geolocation anchor="BMAP_ANCHOR_TOP_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
                      <!-- <bm-marker :position="autoLocationPoint" v-if="initLocation">
                                                </bm-marker> -->
                    </baidu-map>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="机构介绍">
                      <quill-editor v-model="orgDetail.abstract" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="demo-drawer__footer">
                  <el-button type="primary" @click="SaveOrganization('orgDetail')">保存</el-button>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Organization } from '../../../components/HospitalDomain/Organization'
import { Role } from '../../../components/HospitalDomain/Role'
import { Region } from '../../../components/HospitalDomain/Region'
import { OrganizationLevel } from '../../../components/HospitalDomain/OrganizationLevel'
import { OrganizationType } from '../../../components/HospitalDomain/OrganizationType'
import { Product } from '@/components/HospitalDomain/Product.js'
import { Industry } from '@/components/HospitalDomain/Industry.js'
export default {
  name: 'Organization',
  components: {
    bread,
  },
  data() {
    var product = new Product(this.TokenClient, this.Services.Authorization)
    var industry = new Industry(this.TokenClient, this.Services.Authorization)
    var role = new Role(this.TokenClient, this.Services.Authorization)
    var region = new Region(this.TokenClient, this.Services.Authorization)
    var organization = new Organization(this.TokenClient, this.Services.Authorization)
    var organizationLevel = new OrganizationLevel(this.TokenClient, this.Services.Authorization)
    var organizationType = new OrganizationType(this.TokenClient, this.Services.Authorization)
    var organization2 = new Organization(this.TokenClient, this.Services.Healthy)
    return {
      Product: product,
      IndustryDomain: industry,
      Region: region,
      OrganizationDomain: organization,
      OrganizationDomain2: organization2,
      OrganizationLevel: organizationLevel,
      OrganizationType: organizationType,
      RoleDomain: role,
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      RegionData: [],
      LevelData: [],
      TypeData: [],
      industryList: [],
      orgDetail: {
        id: 0,
        name: '',
        alias: '',
        address: '',
        phone: '',
        mail: '',
        contactName: '',
        contactPhone: '',
        levelId: null,
        typeId: null,
        status: true,
        regionId: null,
        industryId: '',
        abstract: '',
        parentId: null,
        distributionMode: 0,
        EditItem: null,
        longitude: 0,
        latitude: 0,
      },
      industryId: '',
      QRCode: '',
      rules: {
        organizationName: [{ required: true, message: '请输入机构名称', trigger: 'blur' }],
        account: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入管理员名称', trigger: 'blur' }],
        regionId: [{ required: true, message: '请选择地址', trigger: 'change' }],
        address: [{ required: true, message: '请地图选点', trigger: 'change' }],
      },
      searchAddresKeywords: '',
      // 百度地图参数
      addressInfo: {
        // 地址信息
        longitude: '', // 经度
        latitude: '', // 纬度
        province: '', // 省
        city: '', // 市
        district: '', // 区
        address: '', // 详细地址
      },
      centerDialog: false,
      location: { lng: 116.403961, lat: 39.915112 }, // 设置中心点坐标lng: 116.403961, lat: 39.915112;
      zoom: 14,
      map: {},
    }
  },
  mounted() {
    this.InitialRegion()
    this.getOrgDetail()
    this.getQRCode()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    SaveOrganization(formName) {
      var _this = this
      var item = _this.orgDetail
      console.log(item)
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if (item.addressOther) {
            item.address = item.address + item.addressOther
          }
          _this.OrganizationDomain.EditHospital(
            item,
            function (data) {
              _this.$message({
                type: 'success',
                message: '保存成功!',
              })
              _this.getOrgDetail()
            },
            function (err) {
              console.log(err)
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 搜索框的搜索事件
    confirmAddress(e) {
      let that = this
      that.map.clearOverlays() // 移除地图上的标注
      var local = new BMap.LocalSearch(that.map, {
        //智能搜索
        onSearchComplete: function (results) {
          let poi = results.getPoi(0) //获取第一个智能搜索的结果
          var searchpt = poi.point // 获取坐标
          that.map.centerAndZoom(searchpt, 19)
          that.map.addOverlay(new BMap.Marker(searchpt)) //添加标注
          that.geocAddress(searchpt) // 逆地址解析
        },
      })
      // 搜索词
      local.search(e)
    },
    mapReady({ BMap, map }) {
      let that = this
      that.map = map
      map.clearOverlays() // 移除地图上的标注
      // 定位当前位置偏差较大
      if (that.addressInfo.longitude && that.addressInfo.latitude) {
        var searchpt = {
          lng: that.addressInfo.longitude,
          lat: that.addressInfo.latitude,
        }
        map.centerAndZoom(new BMap.Point(that.addressInfo.longitude, that.addressInfo.latitude), 15)
        var marker = new BMap.Marker(searchpt) // 创建标注
        map.addOverlay(marker) // 将标注添加到地图中
      } else {
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
              map.centerAndZoom(new BMap.Point(r.point.lng, r.point.lat), 15)
            }
          },
          { enableHighAccuracy: true }
        )
      }
      // 添加地图点击事件
      map.addEventListener('click', function (e) {
        var clickpt = e.point // 点击的坐标
        console.log(clickpt)
        map.clearOverlays() // 移除地图上的标注
        var marker = new BMap.Marker(clickpt) // 创建标注
        map.addOverlay(marker) // 将标注添加到地图中
        // 逆地址解析
        that.geocAddress(clickpt)
      })
    },
    /** 逆向解析地址 point */
    geocAddress(point) {
      let that = this
      var geoc = new BMap.Geocoder()
      geoc.getLocation(point, function (e) {
        console.log('e', point)
        console.log('e', e)
        // 设置基本信息
        var addressInfo = e.addressComponents
        that.addressInfo.longitude = point.lng
        that.addressInfo.latitude = point.lat
        that.addressInfo.province = addressInfo.province
        that.addressInfo.city = addressInfo.city
        that.addressInfo.district = addressInfo.district
        let address = addressInfo.street + addressInfo.streetNumber
        if (e.surroundingPois.length > 0) {
          address = address + e.surroundingPois[0].title
        }
        that.addressInfo.address = address
        that.orgDetail.longitude = that.addressInfo.longitude
        that.orgDetail.latitude = that.addressInfo.latitude
        that.orgDetail.address = that.addressInfo.address
        console.log('addressInfo', that.addressInfo)
      })
    },
    InitialRegion() {
      var _this = this
      _this.Region.Regions(
        null,
        function (data) {
          _this.RegionData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    LasyLoadRegion(node, resolve) {
      var _this = this
      _this.Region.Regions(
        node.value,
        function (data) {
          for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
            data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 5
          }
          resolve(data.data)
        },
        function (err) {
          resolve([])
          console.log(err)
        }
      )
    },
    getQRCode() {
      var _this = this
      // env_version: "trial" //体验版
      // env_version:"develop", //开发版
      // env_version: "release"//正式版
      var env_version = 'release'
      if (process.env.NODE_ENV == 'development') {
        env_version = 'develop'
      }
      var param = {
        page: 'pages/login/login',
        scene: 'organizationId=' + _this.organizationId,
        check_path: false,
        env_version: env_version,
      }
      console.log(env_version)
      _this.OrganizationDomain2.QRCode(
        param,
        function (data) {
          _this.QRCode = data.data
          // console.log(data.data);
          // _this.CategoryList =data.data;
        },
        function (error) {
          console.log(error)
        }
      )
    },
    InitialLevel(id) {
      var _this = this
      _this.LevelData = []
      _this.OrganizationLevel.LevelByProduct(
        id,
        function (data) {
          _this.LevelData = [].slice.call(data.data)
        },
        function (err) {
          console.log(err)
          _this.LevelData = []
        }
      )
    },
    InitialType(id) {
      var _this = this
      _this.TypeData = []
      _this.OrganizationType.OrganizationTypeByProduct(
        id,
        function (data) {
          _this.TypeData = [].slice.call(data.data)
        },
        function (err) {
          console.log(err)
          _this.TypeData = []
        }
      )
    },
    FindIndustry(id) {
      var _this = this
      _this.IndustryDomain.IndustryByProductId(
        id,
        '',
        function (data) {
          _this.industryList = data.data
        },
        function (error) {
          console.log(error)
        }
      )
    },
    getOrgDetail() {
      var _this = this
      _this.OrganizationDomain.OrgDetail(
        _this.organizationId,
        function (data) {
          _this.orgDetail = data.data
          _this.addressInfo.longitude = data.data.longitude
          _this.addressInfo.latitude = data.data.latitude
          _this.orgDetail.levelId = _this.orgDetail.level?_this.orgDetail.level.id:null
          _this.orgDetail.typeId = _this.orgDetail.type?_this.orgDetail.type.id:null
          _this.industryId = _this.orgDetail.industry?_this.orgDetail.industry.id:null
          _this.FindIndustry(_this.orgDetail.productId)
          _this.InitialLevel(_this.orgDetail.productId)
          _this.InitialType(_this.orgDetail.productId)
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
.dialog-form {
  width: 430px;
}

/**/
.addhospital {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.addhospital .el-form {
  flex: 1;
}

.addhospital .form-box {
  padding: 30px 50px 30px 30px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.demo-drawer__footer {
  display: flex;
  margin-left: 100px;
}

.headImg {
  position: relative;
  margin-bottom: 25px;
}

.headimgBox {
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  margin: 0 auto;
  overflow: hidden;
  /* border: 5px solid rgba(12, 154, 154, 0.28); */
}

.headimgBox img {
  width: 100%;
}

.headImg .upload-btn {
  position: absolute;
  left: 57%;
  bottom: 0px;
}

.bm-view {
  display: block;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

.BMap_noprint.anchorTL {
  width: 22%;
  top: 10px !important;
  left: 10px !important;
}
</style>
<style>
.staff .el-drawer {
  background-color: #eff7f8;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
  overflow: scroll !important;
}
</style>
